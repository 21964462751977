import Vue from 'vue'
import App from './App.vue'
import LatteUI from "@bybas/latte-ui";
Vue.use(LatteUI, {emojiEnabled: false});
Vue.use(LatteUI);
import "@bybas/latte-ui/src/scss/app.scss";

Vue.config.productionTip = false;
new Vue({
  render: h => h(App),
}).$mount('#app');
