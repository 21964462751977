<template>
  <div v-cloak @drop.prevent="handleFileDragged" @dragover.prevent id="app">
    <div class="container my-4">
      <div class="panel">
        <div class="panel-body">
          <p v-if="uploads">
            Er zijn in totaal {{ uploads }} bestanden geupload.
          </p>
          <div class="form-group">
            <input @change="handleApiKey" type="text" v-model="key" class="form-control" placeholder="Enter your API key"/>
          </div>

          <div class="form-group">
            <div v-if="error" class="notice notice-error">Er is wat fout gegaan tijdens het uploaden!</div>
          </div>
          <div class="form-group">
            <label :disabled="!key || key.length <= 8 || progress" for="upload"  class="btn btn-contained btn-primary">
              <span>Selecteer bestand</span>
            </label>
            <input @change="handleFile" class="hidden" type="file" id="upload" name="upload">
            <template v-if="file">
              <p>Bezig met {{ file.name }} uploaden ... {{ progress }}%</p>
            </template>
          </div>
        </div>
      </div>
      <div v-if="history.length > 0" class="panel">
        <div class="panel-header">
          <span class="panel-title">History</span>
          <div class="ml-auto">
            <button @click="clearHistory" class="btn btn-text btn-primary"><span>Clear history</span></button>
          </div>
        </div>
        <div class="list">
          <template>
            <div v-for="item in history.slice().reverse()" class="list-item">
                <template v-if="typeof item === 'string'">
                  <div class="list-item-caption">
                      <a target="_blank" :href="item">{{ item }}</a>
                  </div>
                </template>
                <template v-else-if="typeof item === 'object'">
                  <div class="list-item-caption">
                      <a target="_blank" :href="item.url">{{ item.url }}</a>
                    <div class="text-soft">{{ item.name }}</div>
                  </div>
                </template>
              </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'App',
  data() {
    return {
      key: localStorage.getItem('key'),
      file: null,
      progress: null,
      error: false,
      success: false,
      toggleScreen: false,
      uploads: 0,
      history: []
    };
  },
  created() {
    console.log(`backend ${process.env.VUE_APP_INFO_URL}`);
    if (localStorage.getItem('history') ) {
      this.history = JSON.parse(localStorage.getItem('history'));
    }
    axios.get(process.env.VUE_APP_INFO_URL)
    .then(({data: {uploads}}) =>  this.uploads = uploads);
  },
  methods: {
    handleApiKey() {
      localStorage.setItem('key', this.key);
    },
    handleFileDragged({dataTransfer: {files}}) {
      this.file = files[0];
      this.submit();
    },
    handleFile({target: {files}}) {
      this.file = files[0];
      this.submit();
    },
    clearHistory() {
      this.history = [];
      localStorage.removeItem('history');
    },
    submit() {
      if (this.progress) {
        return;
      }
      const formData = new FormData();
      formData.append("image", this.file);
      console.log(this.file);
      this.error = false;
      axios
      .post(process.env.VUE_APP_UPLOAD_URL, formData,
              {
                onUploadProgress: progressEvent => {
                  this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                },
                headers: {
                  "Content-Type": "multipart/form-data",
                  "authorization": this.key
                }
              })
      .then(({data: {path}}) => {
        this.history.push({
          url: `${process.env.VUE_APP_IMAGE_URL}${path}`,
          name: this.file.name,
          original: this.file
        });
        localStorage.setItem('history', JSON.stringify(this.history));
      })
      .catch(() => this.error = true)
      .finally(() => {
        this.progress = null;
        this.file = null;
      });
    }
  }
}
</script>

<style scoped>
  .hidden {
    display: none;
  }
</style>
<style>
  html,
  body {
    height: 100%;
    margin: 0
  }


  #app {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
</style>
